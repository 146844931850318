<template>
  <div class="content">
    <div class="" style="width:100%;height: 93vh;">
      <iframe
        ref="urlStyle"
        class="urlStyle"
        :src="url"
        width="100%"
        frameborder="0"
        scrolling="auto"
        style="position: absolute; left: 0px"
        sandbox="allow-scripts allow-top-navigation allow-same-origin allow-forms"
      />
    </div>
    <div class="DomesticStyle" @click="goDomesticDetail">
      看电影，得现金，拿返佣 >
    </div>
  </div>
</template>

<script>
import { getMovieUrl } from '@/services/comeOn.js'
import { getUrlParams } from '@/utils/common/utils'

export default {
  name: 'GoMovie',
  data() {
    return {
      url: '',
      mid: '',
      sourceSign: '',
      sharer: '',
      roomId: '',
      mobile: ''
      // iframeHeight:'100vh',
    }
  },
  created() {
    document.domain = 'm.qianzhu8.com'
  },

  mounted() {
    // if (this.$route.query.env && this.$route.query.env === "99") {
    //     this.mid = this.$route.query.mid;
    // } else {
    //
    // }
    this.mid = window.localStorage.getItem('uid') || this.$route.query.mid

    if (this.mid === undefined || this.mid === false) {
      this.$router.push({
        path: '/login',
        query: {
          codeNumber: this.$route.query.codeNumber
        }
      })
      return
    }
    this.roomId = ''
    const { sourceSign = null, codeNumber = '' } = getUrlParams()
    // 渠道编码
    this.sourceSign = sourceSign || '1FD4T6FG'
    // 分享者id
    this.sharer = codeNumber || ''

    this.getIframeUrl()
  },
  methods: {
    // 去分佣详情
    goDomesticDetail() {
      this.$router.push({
        path: '/FilmDomesticDetail'
      })
    },
    // 获取接口获取iframeurl
    getIframeUrl() {
      const body = {
        mid: this.mid,
        // mobile: this.mobile,
        sourceSign: this.sourceSign,
        sharer: this.sharer,
        roomId: this.roomId
        // status: false, //获取测试地址（不传取正式地址）
      }
      getMovieUrl(body).then((res) => {
        if (Number(res.code) === 200) {
          this.$nextTick(() => {
            this.url = res.data.url
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html,
body,
#app,
.content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  position: relative;
}

.urlStyle {
  width: 100%;
  height: 100%;
}

.DomesticStyle {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 44px;
  background-color: #e33f44;
  text-align: center;
  line-height: 44px;
  color: #fff;
}
</style>
